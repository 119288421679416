<template>
  <div>
    <!--TITULOS  -->
    <div class="air__utils__heading"> <h5>Reporte de Asistencias</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <!--TITULOS  -->
    <!--BARRA DE BUSQUEDA  -->
    <searchingBar :components="['filter', 'sort', 'pages']"
          :sortByOptions="sortByOptions"
          @Fectch="FetchTable"
          @filterValue="filterValue = $event"
          @sortValue="tableObject.sortValue = $event"
          @pageSize="tableObject.pagination.pageSize = $event"
          @currentPageValue="tableObject.currentPage = $event">
      <div class="row justify-content-start mb-3" slot="customFilters">
        <div class="col-sm-12 col-md-6" >
          <globalPlantFilter @onChangeValue="handleChangePlant"  @filterValue="filterWorkCenter = $event"/>
        </div>
        <div class="col-sm-12 col-md-6" >
          <b-form-group id="shift-group" label="Turno" label-for="shift" description="Debe seleccionar una planta">
            <b-form-select v-model="filterOperationShift" :options="operationShiftsCombo"
                           id="shift" label="Turno" @change="FetchTable()"
                           value-field="id" text-field="name">
              <template slot="first">
                <b-form-select-option value="" >Todas los Turnos</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <b-form-group id="estatus-group" label="Estatus" label-for="estatus">
            <b-form-select v-model="filterStatus" :options="statusCombo"
                           id="estatus" label="Estatus" @change="FetchTable()">
              <template slot="first">
                <b-form-select-option value="" >Todas los Estatus</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <b-form-group id="delay-group" label="Retardo" label-for="delay">
            <b-form-select v-model="filterDelay" :options="delayCombo"
                           id="delay" label="Retardo" @change="FetchTable()">
              <template slot="first">
                <b-form-select-option value="" >Todas las asistencias</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <b-form-group id="overTime-group" label="Hrs.Extra" label-for="overTime">
            <b-form-select v-model="filterOvertime" :options="delayCombo"
                           id="overTime" label="Hrs.Extra" @change="FetchTable()">
              <template slot="first">
                <b-form-select-option value="" >Todas las asistencias</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <b-form-group id="locked-group" label="Cerradas" label-for="locked">
            <b-form-select v-model="filterLocked" :options="delayCombo"
                           id="locked" label="Cerradas" @change="FetchTable()">
              <template slot="first">
                <b-form-select-option value="" >Todas las asistencias</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6">
          <b-form-group id="from-group" label="Inicio del Período"  label-for="from">
            <b-form-datepicker v-model="filterFromDate"
                               reset-button label-reset-button="Limpiar"
                               id="from" label="Fecha inicial" placeholder="Desde"/>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6">
          <b-form-group id="to-group" label="Fin del Período"  label-for="to">
            <b-form-datepicker v-model="filterToDate"
                               reset-button label-reset-button="Limpiar"
                               id="to" label="Fecha final" placeholder="Hasta"/>
          </b-form-group>
        </div>
      </div>
    </searchingBar>
    <!--BARRA DE BUSQUEDA  -->
    <!--TABLA-->
    <a-table bordered
        :scroll="{x:1500}"
        :columns="tableObject.columns"
        :data-source="tableObject.data"
        :pagination="tableObject.pagination"
        :loading="tableObject.loading"
        @change="FetchTable"
        :row-key="record => record.id">
      <template slot="employee" slot-scope="employee">
        <a-descriptions :title="`${employee.name} - ${employee.code}` "   size="small"
                      :column="{ sm: 1}" bordered>
          <a-descriptions-item label="Planta">
            <strong class="text-real-blue">{{ employee.workCenters.name }}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Turno" v-if="employee.operationShifts">
            <strong class="text-real-blue">
              {{ employee.operationShifts.name }}
              <small>({{ moment(employee.operationShifts.initial_time).format('HH:mm') }} -
                {{ moment(employee.operationShifts.final_time).format('HH:mm') }} )</small>
            </strong>
          </a-descriptions-item>
        </a-descriptions>
      </template>
      <template slot="shifts" slot-scope="shift" v-if="shift">
        <a-descriptions :title="`${shift.name}`"   size="small"
                      :column="{ sm: 1,}" bordered>
          <a-descriptions-item label="Inicio de Turno">
            <strong class="text-real-blue"> {{ moment(shift.initial_time).format('HH:mm') }}  </strong>
          </a-descriptions-item>
          <a-descriptions-item label="Fin de Turno">
            <strong>  {{ moment(shift.final_time).format('HH:mm') }}  </strong>
          </a-descriptions-item>
        </a-descriptions>
      </template>
      <template slot="details" slot-scope="data">
        <a-descriptions title="Resumen" size="small" :column="{ sm: 1}" bordered>
          <a-descriptions-item label="Llegada">
            <strong>{{ moment(data.checked_in_at).format('DD/MM/YYYY HH:mm') }} </strong>
          </a-descriptions-item>
          <a-descriptions-item label="Salida">
            <strong v-if="data.checked_out_at">
              {{ moment(data.checked_out_at).format('DD/MM/YYYY HH:mm') }}
            </strong>
            <strong v-else>-</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Retardo">
            <strong class="text-danger" v-if="data.delay">SI</strong>
            <strong class="text-info" v-else>NO</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Hrs.Extra">
            <strong class="text-primary" v-if="data.overtime">{{data.overtime}}</strong>
            <strong class="text-danger" v-else>NO</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Autorización" >
            <strong v-if="data.users" class="text-primary">{{ data.users.name }} &nbsp; </strong>
            <span v-if="data.status">
              <b-icon icon="check" variant="primary" /> Autorizado
            </span>
            <span v-else>
              <b-icon icon="clock-fill" variant="warning" /> Pendiente
            </span>
          </a-descriptions-item>
        </a-descriptions>
      </template>
      <template slot="locked" slot-scope="locked">
        <span v-if="locked" class="bg-success text-white font-size-18 px-4 py-2 rounded">Cerrado</span>
        <span v-else class="bg-warning text-white font-size-18 px-4 py-2 rounded">Abierto</span>

      </template>
      <template slot="action" slot-scope="data">
          <b-button  variant="secondary" @click="OpenModal(data)" pill v-if="!data.locked && data.checked_out_at">
            <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
              <b-icon icon="pencil-square"/> Editar
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Editar">
              <b-icon icon="pencil-square"/>
            </div>
          </b-button>
      </template>
    </a-table>
    <!--TABLA-->
    <!-- MODAL -->
    <b-modal title="Detalle de Reporte de Asistencias" ref="detailModal" size="lg" @hide="CloseModal" >
      <b-container fluid>
        <!--FORMULARIO-->
        <a-form-model :model="resourceObj.form" :rules="formRules" ref="assistForm"
                      layout="vertical" :wrapper-col="{ span: 24}" v-show="!spinnerLoad">
          <!--SELECT -Empleado -->
          <a-form-model-item label="Empleado" ref="operationEmployees" prop="operationEmployees" >
            <a-select v-model="resourceObj.form.operationEmployees" disabled
                      placeholder="Seleccionar Empleado"
                      show-search :filter-option="filterOption">
              <a-select-option  v-for="option in operationEmployeesCombo" :key="option.id" :value="option.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--SELECT -Empleado -->
          <!--SELECT -Turno -->
          <a-form-model-item label="Turno" ref="operationShiftd" prop="operationShiftd" >
            <a-select v-model="resourceObj.form.operationShifts" disabled
                      placeholder="Seleccionar Turno"
                      show-search :filter-option="filterOption">
              <a-select-option  v-for="option in operationShiftsCombo" :key="option.id" :value="option.id" >
                {{ option.name }}
                ({{ moment(option.initial_time).format('HH:mm') }} -
                {{ moment(option.final_time).format('HH:mm') }} )
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--SELECT -Turno -->
          <!--Entrada y salida -->
          <a-row align="middle" justify="center">
            <a-col :sm="{ span: 12 }" :md="{ span:9,offset: 3}">
              <!--DATEPICKER - Entrada-->
              <a-form-model-item  label="Entrada" ref="checked_in_at" prop="checked_in_at" >
                <a-date-picker  show-time disabled v-model="resourceObj.form.checked_in_at" placeholder="Entrada"
                                format="DD/MM/YYYY HH:mm" size="large" valueFormat="YYYY-MM-DD HH:mm:ss"/>
              </a-form-model-item>
              <!--DATEPICKER - Entrada -->
            </a-col>
            <a-col :sm="{ span: 12 }" :md="{ span:9,offset:3}">
              <!--DATEPICKER - Salida-->
              <a-form-model-item  label="Salida" ref="checked_out_at" prop="checked_out_at" >
                <a-date-picker  disabled show-time v-model="resourceObj.form.checked_out_at" placeholder="Salida"
                                format="DD/MM/YYYY HH:mm" size="large" valueFormat="YYYY-MM-DD HH:mm:ss"/>
              </a-form-model-item>
              <!--DATEPICKER - Salida -->
            </a-col>
          </a-row>
          <!--Entrada y salida -->
          <hr class="bg-primary">
          <!-- hrs extrs, retardo -->
          <a-row align="middle" justify="center">
            <a-col :sm="{ span: 16 }" :md="{ span:6, offset: 3}">
              <!--NUMBER INPUT - Horas Extra-->
              <a-form-model-item label="Horas Extra" ref="overtime"  prop="overtime" >
                <a-input-number v-model="resourceObj.form.overtime" :min="0" :step="1" size="large" style="width: 90%"/>
              </a-form-model-item>
              <!--NUMBER INPUT -Horas Extra -->
            </a-col>
            <a-col :sm="{ span: 6, offset: 2 }" :md="{ span:6, offset: 9}">
              <!-- Retardo -->
              <a-form-model-item label="Retardo" ref="delay"  prop="delay" >
                <a-switch v-model="resourceObj.form.delay" :style="`background-color:${resourceObj.form.delay ? 'red' : '#007D3E'}`"
                          checked-children="SI" un-checked-children="NO"/>
              </a-form-model-item>
              <!-- Retardo -->
            </a-col>
          </a-row>
          <!-- hrs extrs, retardo -->
          <hr class="bg-primary">
          <!-- Autorizacion, estatus y bloqueo -->
          <a-row align="middle" justify="center">
            <a-col :sm="{ span: 16 }" :md="{ span:16}">
              <!--SELECT -Autorizador -->
              <a-form-model-item label="Autorizador" ref="users" prop="users" >
                  <a-select v-model="resourceObj.form.users" allowClear
                              placeholder="Seleccionar Autorizador"
                              show-search :filter-option="filterOption">
                      <a-select-option  v-for="option in usersCombo" :key="option.id" :value="option.id" >
                        {{option.name}}
                      </a-select-option>
                  </a-select>
              </a-form-model-item>
              <!--SELECT -Autorizador -->
            </a-col>
            <a-col :sm="{ span: 6, offset:2 }" :md="{ span:6, offset:2 }" v-if="resourceObj.form.users">
              <!-- Estatus de Autorización -->
              <a-form-model-item label="Autorización" ref="status"  prop="status" >
                <a-switch v-model="resourceObj.form.status" checked-children="Autorizado" un-checked-children="Pendiente"/>
              </a-form-model-item>
              <!-- Estatus de Autorización -->
            </a-col>
          </a-row>
          <!-- Autorizacion, estatus y bloqueo -->
          <div class="row justify-content-end">
            <b-button-group>
              <b-button  variant="primary" @click="Submit" pill>
                <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/> Guardar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/>
                </div>
              </b-button>
              <b-button v-show="showLock"  variant="danger" @click="Lock" pill>
                <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                  <b-icon icon="lock-fill"/> Bloquear
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                  <b-icon icon="lock-fill"/>
                </div>
              </b-button>
            </b-button-group>
          </div>
        </a-form-model>
        <my-spinner :load="spinnerLoad"/>
        <!--FORMULARIO-->
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->
  </div>
</template>
<script>
import moment from 'moment'
import { fractalMixin } from '@/mixins/fractalMixin'
import { Modal, notification } from 'ant-design-vue'
export default {
  name: 'assistsReport',
  mixins: [fractalMixin],
  data() {
    return {
      // Constantes
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          name: 'operations',
        },
        {
          breadcrumbName: 'Control de Operaciones',
          name: 'operationControl',
        },
        {
          breadcrumbName: 'Control de Personal',
          name: 'personalControl',
        },
        {
          breadcrumbName: 'Reporte de Asistencias',
          aIcon: '',
        },
      ],
      // Constantes
      // SearchBar
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'registered_at', text: 'Fecha de Registro' },
      ],
      filterValue: '',
      filterWorkCenter: '',
      filterOperationShift: '',
      filterStatus: '',
      filterDelay: '',
      filterOvertime: '',
      filterLocked: '',
      filterFromDate: '',
      filterToDate: '',
      statusCombo: [{ value: 0, text: 'PENDIENTE' }, { value: 1, text: 'AUTORIZADO' }],
      delayCombo: [{ value: 1, text: 'SI' }, { value: 0, text: 'NO' }],
      // SearchBar
      // Tabla
      tableObject: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Imfo de Empleado',
            dataIndex: 'operationEmployees',
            class: 'text-center',
            scopedSlots: { customRender: 'employee' },
          },
          {
            title: 'Detalles de Registro',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'details' },
          },
          {
            title: 'Info de Turno',
            dataIndex: 'operationShifts',
            class: 'text-center',
            scopedSlots: { customRender: 'shifts' },
          },
          {
            title: 'Estatus',
            dataIndex: 'locked',
            class: 'text-center',
            width: '150px',
            scopedSlots: { customRender: 'locked' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            width: '150px',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      // Tabla
      // Objeto principal del recurso
      resourceObj: {
        resourceType: 'staffAssists',
        form: {
          id: '',
        },
        relationships: ['users', 'operationEmployees', 'operationShifts'],
      },
      // Reglas de Formulario
      formRules: {
        // field: [{ required: true, message: 'El campo __ es obligatorio.', trigger: 'blur' }],
      },
      // Combos de Formulario
      operationEmployeesCombo: [],
      operationShiftsCombo: [],
      usersCombo: [],
      // Variables para el flujo del formulario
      spinnerLoad: false,
      showLock: false,
    }
  },
  methods: {
    // CRUD
    async FetchTable(pagEvent = {}) {
      const params = {
        'page[number]': (Object.keys(pagEvent).length !== 0) ? `${pagEvent.current}` : `${this.tableObject.currentPage}`,
        'page[size]': `${this.tableObject.pagination.pageSize}`,
        include: 'operationEmployees.workCenters,operationEmployees.operationShifts,users,operationShifts',
        // sort: this.tableObject.sortValue,
      }
      if (this.filterValue) params['filter[global]'] = this.filterValue.trim()
      if (this.filterWorkCenter) params['filter[workCenters]'] = this.filterWorkCenter
      if (this.filterOperationShift) params['filter[operationShifts]'] = this.filterOperationShift
      if (this.filterDelay !== '') params['filter[delay]'] = this.filterDelay
      if (this.filterOvertime !== '') params['filter[overtime]'] = this.filterOvertime
      if (this.filterStatus !== '') params['filter[status]'] = this.filterStatus
      if (this.filterLocked !== '') params['filter[locked]'] = this.filterLocked
      if (this.filterFromDate !== '' && this.filterToDate !== '') {
        params['filter[timePeriod]'] = `${this.filterFromDate},${this.filterToDate}`
      }
      await this.GetTableResource(pagEvent, this.resourceObj.resourceType, this.tableObject, params)
    },
    async Submit() {
      this.$refs.assistForm.validate(async(valid) => {
        if (valid) {
          this.resourceObj.form.status = this.resourceObj.form.users ? this.resourceObj.form.status : false
          this.spinnerLoad = true
          await this.PutResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async FetchCombos() {
      this.operationEmployeesCombo = await this.GetResource('/operationEmployees', {})
      this.usersCombo = await this.GetResource('/users', {})
      await this.FetchShifts(true)
    },
    lockMustShow(data) {
      if ((data.users !== undefined && data.users !== null) && data.status === true) {
        this.showLock = true
      } else this.showLock = false
    },
    OpenModal(data = {}) {
      this.$refs.detailModal.show()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.showLock = false
        this.ResetForm()
      } else {
        // Update case
        this.lockMustShow(data)
        this.FetchShifts(false)
        this.resourceObj.form = {
          id: data.id.toString(),
          operationEmployees: data.operationEmployees.id.toString(),
          users: data.users ? data.users.id.toString() : undefined,
          operationShifts: data.operationShifts.id.toString(),
          checked_in_at: moment(data.checked_in_at).format('YYYY-MM-DD HH:mm:ss'),
          checked_out_at: data.checked_out_at ? moment(data.checked_out_at).format('YYYY-MM-DD HH:mm:ss') : '',
          delay: data.delay,
          overtime: data.overtime,
          status: data.status,
          locked: data.locked,
        }
      }
    },
    CloseModal() {
      this.$refs.detailModal.hide()
      this.ResetForm()
    },
    ResetForm() {
      this.resourceObj.form = {
        id: '',
        checked_in_at: '',
        checked_out_at: '',
        delay: false,
        overtime: 0,
        status: false,
        locked: false,
        users: undefined,
        operationEmployees: undefined,
        operationShifts: undefined,
      }
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.CloseModal()
    },
    // CRUD
    async FetchShifts(strict = true) {
      const params = {
        sortBy: 'name',
      }
      if (strict) {
        params['filter[workCenters]'] = this.filterWorkCenter
      }

      this.operationShiftsCombo = await this.GetResource('/operationShifts', params)
    },
    async handleChangePlant() {
      await this.FetchShifts(true)
      await this.FetchTable()
    },
    // Extra
    async Lock() {
      if (this.resourceObj.form.users && this.resourceObj.form.status) {
        const auxThis = this
        Modal.confirm({
          title: '¿Esta seguro de bloquear este registro?',
          content: 'Esta operación es irreversible',
          okText: 'Sí',
          okType: 'danger',
          cancelText: 'No',
          zIndex: 3000,
          async onOk () {
            auxThis.spinnerLoad = true
            await auxThis.AxiosPut(`lock_assist/${auxThis.resourceObj.form.id}`, {}, auxThis.SuccessCallback, () => { auxThis.spinnerLoad = false })
          },
          onCancel () {
            notification.info({
              message: 'No se ha eliminado el registro',
            })
          },
        })
        auxThis.spinnerLoad = false
      }
    },
    // Extra
  },
  async mounted() {
    await this.FetchTable()
    await this.FetchCombos()
  },
}
</script>
<style scoped></style>
